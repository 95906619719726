import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { ThemeContext, ThemeProvider } from "../ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faMoon,
  faFont,
  faRss,
  faUser,
  faPlus,
  faMinus,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import "@fortawesome/fontawesome-svg-core/styles.css";
import styles from "./ContextualBar.module.scss";
import { onEscapeSpace } from "../../utils.js";
import classnames from "classnames";
import { useMedia, useLocation } from "react-use";

const Links = () => {
  const links = [
    {
      icon: faRss,
      label: "RSS",
      url: "/rss.xml",
      target: "_blank",
      rel: "noreferrer noopener",
      // @todo: necessary for now on gatsby
      isExternal: true,
    },
    {
      icon: faUser,
      label: "rafael g",
      url: "https://rafaelg.net.br",
      target: "_blank",
      rel: "noreferrer noopener",
      isExternal: true,
    },
  ];

  return (
    <ul className={styles.links}>
      {links.map(({ url, label, isExternal, icon, ...others }) => (
        <li>
          {!!icon && <FontAwesomeIcon icon={icon} />}
          {isExternal ? (
            <a href={url} {...others}>
              {label}
            </a>
          ) : (
            <Link to={url} {...others}>
              {label}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};

const FontMenu = () => {
  const {
    defaultFont,
    setDefaultFont,
    fontSizeIncrease,
    fontSizeDecrease,
    fontSize,
  } = useContext(ThemeContext);

  return "";
};

const MobileMenu = () => {
  const {
    colorMode,
    setColorMode,
    defaultFont,
    setDefaultFont,
    fontSizeIncrease,
    fontSizeDecrease,
    fontSize,
  } = useContext(ThemeContext);

  const buttons = [
    {
      id: "fontFamilyButton",
      onClick: () => setDefaultFont(defaultFont === "sans" ? "serif" : "sans"),
      onKeyDown: (evt) =>
        onEscapeSpace(evt, () =>
          setDefaultFont(defaultFont === "sans" ? "serif" : "sans")
        ),
      icon: faFont,
      text: defaultFont === "sans" ? " Serifa" : " Sem serifa",
    },
    {
      id: "fontSizeIncreaseButton",
      onClick: () => fontSizeIncrease(),
      onKeyDown: (evt) => onEscapeSpace(evt, () => fontSizeIncrease()),
      text: <b>A+</b>,
    },
  ];

  return "";
};

const ContextualBar = ({ isReadingPage }) => {
  const {
    colorMode,
    setColorMode,
    defaultFont,
    setDefaultFont,
    fontSize,
    fontSizeIncrease,
    fontSizeDecrease,
  } = useContext(ThemeContext);

  const isMobile = useMedia("screen and (max-width: 600px)", false);
  const { pathname } = useLocation();

  const shareOnMobile = useCallback(() => {
    const data = {
      title: document.title,
      // text: "Test", TODO
      url: pathname,
    };
    if (navigator.canShare && navigator.canShare(data))
      navigator
        .share(data)
        .then(() => console.log("Successfully shared"))
        .catch((err) => console.error(err));
  }, []);

  const buttons = useMemo(() => {
    const ret = !isReadingPage
      ? [
          {
            id: "colorModeButton",
            onClick: () =>
              setColorMode(colorMode === "dark" ? "light" : "dark"),
            onKeyDown: (evt) =>
              onEscapeSpace(evt, () =>
                setColorMode(colorMode === "dark" ? "light" : "dark")
              ),
            icon: colorMode === "dark" ? faSun : faMoon,
            text: ` Tema ${colorMode === "dark" ? "claro" : "escuro"}`,
            grow: 1,
          },
        ]
      : [
          {
            id: "colorModeButton",
            onClick: () =>
              setColorMode(colorMode === "dark" ? "light" : "dark"),
            onKeyDown: (evt) =>
              onEscapeSpace(evt, () =>
                setColorMode(colorMode === "dark" ? "light" : "dark")
              ),
            icon: colorMode === "dark" ? faSun : faMoon,
            text: ` Tema ${colorMode === "dark" ? "claro" : "escuro"}`,
            grow: 0,
          },
          {
            id: "fontFamilyButton",
            onClick: () =>
              setDefaultFont(defaultFont === "sans" ? "serif" : "sans"),
            onKeyDown: (evt) =>
              onEscapeSpace(evt, () =>
                setDefaultFont(defaultFont === "sans" ? "serif" : "sans")
              ),
            icon: faFont,
            text: defaultFont === "sans" ? " Serifa" : " Sem serifa",
            grow: 0,
          },
          {
            id: "fontSizeDecreaseButton",
            onClick: () => fontSizeDecrease(),
            onKeyDown: (evt) => onEscapeSpace(evt, () => fontSizeDecrease()),
            complexIcon: () => (
              <span
                className="fa-layers fa-fw"
                style={{ marginRight: "0.5rem" }}
              >
                <FontAwesomeIcon icon={faFont} />
                <FontAwesomeIcon icon={faMinus} transform="shrink-6 right-10" />
              </span>
            ),
            text: "Diminuir fonte",
            grow: 0,
          },
          {
            id: "fontSizeIncreaseButton",
            onClick: () => fontSizeIncrease(),
            onKeyDown: (evt) => onEscapeSpace(evt, () => fontSizeIncrease()),
            complexIcon: () => (
              <span
                className="fa-layers fa-fw"
                style={{ marginRight: "0.5rem" }}
              >
                <FontAwesomeIcon icon={faFont} />
                <FontAwesomeIcon icon={faPlus} transform="shrink-6 right-10" />
              </span>
            ),
            text: "Aumentar fonte",
            grow: 0,
          },
        ];

    if (isMobile)
      ret.push({
        id: "shareButton",
        onClick: () => shareOnMobile(),
        onKeyDown: (evt) => onEscapeSpace(evt, () => shareOnMobile()),
        icon: faShareAlt,
        text: " Compartilhar",
        grow: 0,
      });

    return ret;
  }, [
    isMobile,
    isReadingPage,
    colorMode,
    defaultFont,
    fontSizeIncrease,
    fontSizeDecrease,
    setColorMode,
    setDefaultFont,
    shareOnMobile,
  ]);

  return (
    <>
      <div
        className={classnames(styles.topBar, {
          [styles.fixed]: !!isReadingPage,
          [styles.mobile]: !!(isMobile && isReadingPage),
        })}
      >
        <div className={styles.wrapper}>
          <div className={styles.buttons}>
            {typeof window === "undefined" ? (
              <span />
            ) : (
              buttons.map(
                ({ id, onClick, onKeyDown, icon, complexIcon, text, grow }) => (
                  <span
                    key={id}
                    id={id}
                    role="button"
                    tabIndex={0}
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    style={{
                      "--grow": grow,
                    }}
                  >
                    {!!icon && <FontAwesomeIcon icon={icon} />}
                    {!!complexIcon && complexIcon()}
                    {!(isMobile && isReadingPage) && !!text && text}
                  </span>
                )
              )
            )}
          </div>
          {!(isMobile & isReadingPage) && <Links />}
        </div>
      </div>
    </>
  );
};

export default ContextualBar;
