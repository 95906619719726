import React from "react";
import styles from "./Footer.module.scss";

const Footer = () => (
  <footer className={styles.footer}>
    <div>
      <a href=" https://creativecommons.org/licenses/by-sa/4.0/">
        CC BY-SA 4.0
      </a>
      <span>©</span>
      2024 Rafael Gonçalves{" "}
    </div>
  </footer>
);

export default Footer;
