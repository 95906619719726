import React, { useContext, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import ContextualBar from "../ContextualBar";
import { ThemeContext, ThemeProvider } from "../ThemeContext";
import Helmet from "react-helmet";
import { graphql, useStaticQuery, withPrefix } from "gatsby";
import "../../styles/style.scss";
import styles from "./Layout.module.scss";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false; /* eslint-disable import/first */

const Layout = ({ className, header, children, isReadingPage }) => {
  const data = useStaticQuery(graphql`
    query layoutQuery {
      site {
        siteMetadata {
          title
          lang
          author
          description
          icon
        }
      }
    }
  `);

  const { colorMode, defaultFont, fontSize } = useContext(ThemeContext);

  useEffect(() => {
    const root = document.documentElement;

    if (colorMode === "dark") {
      root.className = "dark";
    } else {
      root.className = "";
    }

    root.style.setProperty(
      "--reading-font",
      defaultFont === "sans" ? "var(--sans-font)" : "var(--serif-font)"
    );

    root.style.setProperty("--reading-size", `${fontSize}rem`);
  }, [colorMode, defaultFont, fontSize]);

  return (
    <>
      <Helmet
        titleTemplate={`%s | ${data.site.siteMetadata.title}`}
        defaultTitle={data.site.siteMetadata.title}
      >
        <html lang={data.site.siteMetadata.lang} />
        <meta charSet="utf-8" />
        <meta name="author" content={data.site.siteMetadata.author} />
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta
          name="icon"
          type="image/x-icon"
          href={withPrefix(data.site.siteMetadata.icon)}
        />
      </Helmet>
      <div className={styles.wrapper}>
        <ContextualBar isReadingPage={isReadingPage} />
        <Header />
        <main className={className}>
          {header && (
            <header className={styles.header}>
              <h1>{header}</h1>
            </header>
          )}
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
};

const LayoutWrapper = (props) => (
  <ThemeProvider>
    <Layout {...props} />
  </ThemeProvider>
);

export default LayoutWrapper;
