import React from "react";

function getInitialColorMode() {
  if (!(typeof window === "undefined")) {
    const persistedColorPreference = window.localStorage.getItem("color-mode");
    const hasPersistedPreference = typeof persistedColorPreference === "string";
    // If the user has explicitly chosen light or dark,
    // let's use it. Otherwise, this value will be null.
    if (hasPersistedPreference) {
      return persistedColorPreference;
    }
    // If they haven't been explicit, let's check the media
    // query
    const mql = window.matchMedia("(prefers-color-scheme: dark)");
    const hasMediaQueryPreference = typeof mql.matches === "boolean";
    if (hasMediaQueryPreference) {
      return mql.matches ? "dark" : "light";
    }
    // If they are using a browser/OS that doesn't support
    // color themes, let's default to 'light'.
    return "light";
  }
}

function getInitialFont() {
  if (!(typeof window === "undefined")) {
    const persistedFontPreference = window.localStorage.getItem("default-font");
    const hasPersistedPreference = typeof persistedFontPreference === "string";
    if (hasPersistedPreference) {
      return persistedFontPreference;
    }
    return "serif";
  }
}

function getInitialSize() {
  if (!(typeof window === "undefined")) {
    const persistedSizePreference = window.localStorage.getItem("font-size");
    const hasPersistedPreference = typeof persistedSizePreference === "string";
    if (hasPersistedPreference) {
      return persistedSizePreference;
    }
    return 1;
  }
}

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  const [colorMode, rawSetColorMode] = React.useState(getInitialColorMode);
  const [defaultFont, rawSetDefaultFont] = React.useState(getInitialFont);
  const [fontSize, rawSetFontSize] = React.useState(getInitialSize);

  const setColorMode = (value) => {
    rawSetColorMode(value);
    window.localStorage.setItem("color-mode", value);
  };

  const setDefaultFont = (value) => {
    rawSetDefaultFont(value);
    window.localStorage.setItem("default-font", value);
  };

  const setFontSize = (value) => {
    rawSetFontSize(value);
    window.localStorage.setItem("font-size", value);
  };

  const fontSizeIncrease = () => setFontSize(Math.min(fontSize + 0.25, 2));
  const fontSizeDecrease = () => setFontSize(Math.max(fontSize - 0.25, 0.5));

  return (
    <ThemeContext.Provider
      value={{
        colorMode,
        setColorMode,
        defaultFont,
        setDefaultFont,
        fontSize,
        setFontSize,
        fontSizeIncrease,
        fontSizeDecrease,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
