import React, { useState } from "react";
import { navigate } from "gatsby";
import styles from "./SearchBtn.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

const SearchBtn = () => {
  const [inputStr, setInputStr] = useState(undefined);
  const submitHandler = (evt) => {
    evt.preventDefault();
    if (!!inputStr) {
    	navigate(`/?search=${inputStr}`);
    }
    else {
	navigate("/");
    }
  };
  return (
    <form onSubmit={submitHandler} className={styles.searchBtn}>
      <input
        name="search"
        id="search"
        type="search"
        placeholder="Buscar neste site"
        value={inputStr}
        onInput={(evt) => setInputStr(evt.target.value)}
      />
      <label htmlFor="search">
        <button type="submit">
          <FontAwesomeIcon aria-hidden="true" title="Buscar" icon={faSearch} />
	  <span class="hidden">Buscar neste site</span>
        </button>
      </label>
      <button type="submit" className={styles.close} onClick={() => setInputStr("")}>
	  <FontAwesomeIcon aria-hidden="true" title="Excluir" icon={faTimes} />
	  <span class="hidden">Excluir busca</span>
      </button>
    </form>
  );
};

export default SearchBtn;
