import React from "react";
import { Link } from "gatsby";
import styles from "./Header.module.scss";
import SearchBtn from "../SearchBtn";

const Header = () => (
  <header className={styles.header}>
    <div className={styles.headerContent}>
      <div className={styles.titleWrap}>
        <Link to="/">
          <div className={styles.title}>Maquinações</div>
        </Link>
        <div className={styles.description}>
          Experimentações teóricas, fichamentos e outros comentários
          descartáveis
        </div>
      </div>
      <div className={styles.separator} />
      <nav id="main-menu" className={styles.menu}>
        <ul className={styles.menuList}>
          <li>
            <Link to="/">Início</Link>
          </li>
          <li>
            <Link to="/sobre">Sobre</Link>
          </li>
          <li>
            <Link to="/tags">Tags</Link>
          </li>
        </ul>
        <SearchBtn />
      </nav>
    </div>
  </header>
);

export default Header;
